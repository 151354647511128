import { addPropertyControls, ControlType } from "framer"
import { useEffect, useContext, useRef, useState } from "react"
import { motion } from "framer-motion"
import {
    FormIcon,
    iconPropertyControls,
    useFormStore,
} from "https://framer.com/m/SharedFormCode-HQOZ.js@YpgjMrjs07OJc6jTFbm7"

const NO_REQUIRED_TYPES = ["checkbox", "consent", "slider"]
const NO_PREFILL_TYPES = ["consent"]
const CHARACTER_LIMIT_TYPES = ["input", "textArea"]
const CHECKBOX_TYPES = ["checkbox", "consent", "multiSelect"]
const OPTIONS_TYPES = ["dropdown", "radio", "multiSelect"]
const TEXT_PLACEHOLDER_TYPES = ["input", "textArea"]
const PLACEHOLDER_FONT_COLOR_TYPES = [
    "input",
    "textArea",
    "number",
    "email",
    "phoneNumber",
    "dropdown",
]
const GAP_TYPES = ["checkbox", "consent", "slider", "nps", "phoneNumber"]
const GAP_HV_TYPES = ["radio", "multiSelect"]
const MIN_MAX_STEP_TYPES = ["number", "slider"]
const PHONE_NUMBER_FORMATS = [
    "(123) 456 - 7890",
    "(123) 456-7890",
    "123-456-7890",
    "123.456.7890",
    "123 456 7890",
    "1234567890",
    "12-3456-7890",
]
const INVALID_STATE = {
    valid: false,
    required: "required",
    value: "value",
}
const DROPDOWN_ICON_HEIGHT = 12
const DROPDOWN_NONE_SELECTED_VALUE = "[{(None Selected)}]"
const DASHES_ONLY_REGEX = /^-+$/

/**
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any
 * @framerIntrinsicWidth 500
 */
export default function FormField(props) {
    const {
        formId,
        type,
        name,
        options,
        gap,
        border,
        icon,
        invalidStyle,
        checkboxStyle,
    } = props

    const elementId = name

    const required = NO_REQUIRED_TYPES.includes(type) ? true : props.required
    const iconOnLeft = icon?.position == "left"

    const pt = props.paddingIsMixed ? props.paddingTop : props.padding
    const pl = props.paddingIsMixed ? props.paddingLeft : props.padding
    const pb = props.paddingIsMixed ? props.paddingBottom : props.padding
    const pr = props.paddingIsMixed ? props.paddingRight : props.padding
    const plIcon = pl + (icon && iconOnLeft ? icon.size + icon.gap : 0)
    const prIcon = pr + (icon && !iconOnLeft ? icon.size + icon.gap : 0)

    const minHeight = icon ? pt + icon.size + pb : undefined

    const [formState, setFormState] = useFormStore()
    const value = formState[formId]?.[name]?.value

    // Used by the phone number input field to record if the country code is the default
    // country code and should be hidden or if it was entered by the user and should be shown.
    const [isSpecialValue, setIsSpecialValue] = useState(
        type == "phoneNumber" && props.phoneNumberDefaultCountryCode.length > 0
    )

    const [invalid, setInvalid] = useState(INVALID_STATE.valid)
    const invalidRef = useRef(INVALID_STATE.valid)
    const ref = useRef(null)

    function updateField(key, value) {
        setFormState((prev) => ({
            [formId]: {
                ...prev[formId],
                [name]: {
                    ...prev[formId]?.[name],
                    [key]: value,
                },
            },
        }))
    }

    function invalidate(newState) {
        invalidRef.current = newState
        setInvalid(newState)
    }

    function revalidate() {
        invalidRef.current = INVALID_STATE.valid
        setInvalid(INVALID_STATE.valid)
    }

    function isValid(value) {
        let valid = true

        if (required) {
            switch (type) {
                case "input":
                case "textArea":
                case "phoneNumber":
                case "email":
                case "url":
                    valid = value && value.length > 0
                    break
                case "multiSelect":
                    valid = Array.isArray(value) && value.length > 0
                    break
                case "consent":
                    valid = value == "on"
                    break
                default:
                    valid = value != null
                    break
            }
        }

        // Invalidate and early return
        if (!valid) {
            invalidate(INVALID_STATE.required)
            return valid
        }

        // Check field value validity
        switch (type) {
            case "email":
                if (value.length > 0 && !isEmailAddress(value)) {
                    valid = false
                }
                break
            case "input":
            case "textArea":
                if (
                    props.textCharacterLimit &&
                    (value.length < props.textCharacterLimit.min ||
                        value.length > props.textCharacterLimit.max)
                ) {
                    valid = false
                }
                break
            case "url":
                if (
                    value.length > 0 &&
                    !parseURL(value, props.urlAcceptedDomains)[0]
                ) {
                    valid = false
                }
                break
        }

        if (!valid) {
            invalidate(INVALID_STATE.value)
        }

        return valid
    }

    useEffect(() => {
        let defaultValue = null
        switch (type) {
            case "input":
            case "textArea":
            case "email":
            case "phoneNumber":
            case "url":
                defaultValue = ""
                break
            case "checkbox":
                defaultValue = "off"
                break
            case "dropdown":
                defaultValue = options.includes(props.dropdownDefaultValue)
                    ? props.dropdownDefaultValue
                    : null
                break
            case "multiSelect":
                defaultValue = []
                break
            case "slider":
                defaultValue = props.sliderDefaultValue
                break
        }

        let prefillValue = null

        if (props.prefill && !NO_PREFILL_TYPES.includes(type)) {
            const urlParam = props.prefillUrlParameter || props.name
            const searchParams = new URLSearchParams(window.location.search)

            if (searchParams.has(urlParam)) {
                const param = searchParams.get(urlParam)

                switch (type) {
                    case "checkbox":
                        prefillValue = ["true", "yes", "on", "y"].includes(
                            param.toLowerCase()
                        )
                            ? "on"
                            : "off"
                        break
                    case "number":
                        if (isNumber(param)) {
                            prefillValue = Number(param)
                        }
                        break
                    case "slider":
                        if (isNumber(param)) {
                            prefillValue = Math.min(
                                Math.max(Number(param), props.min),
                                props.max
                            )
                        }
                        break
                    case "multiSelect":
                        const values = param.split(/,\s*/)

                        prefillValue = []
                        for (const value of values) {
                            if (options.includes(value)) {
                                prefillValue.push(value)
                            }
                        }
                        break
                    case "radio":
                    case "dropdown":
                        if (options.includes(param)) {
                            prefillValue = param
                        }
                        break
                    case "nps":
                        if (isNumber(param)) {
                            prefillValue = Math.min(
                                Math.max(Number(param), 0),
                                props.npsMax
                            )
                        }
                        break
                    default:
                        if (param !== "") {
                            prefillValue = param
                        }
                        break
                }
            }
        }

        setFormState((prev) => {
            return {
                [formId]: {
                    ...prev[formId],
                    [name]: {
                        ref,
                        class: "formField",
                        value: prev[formId]?.[name]
                            ? prev[formId]?.[name].value
                            : prefillValue ?? defaultValue,
                        defaultValue,
                        name,
                        required,
                        visible: true,
                        isValid,
                    },
                },
            }
        })

        return () => {
            updateField("visible", false)
        }
    }, [])

    const borderRadius = props.radiusIsMixed
        ? `${props.radiusTopLeft}px ${props.radiusTopRight}px ${props.radiusBottomRight}px ${props.radiusBottomLeft}px`
        : `${props.radius}px`
    const style = {
        flex: 1,
        border: "none",
        outline: "none",
        backgroundColor: "transparent",
        padding: `${pt}px ${prIcon}px ${pb}px ${plIcon}px`,
        color: props.fontColor,
        ...props.font,
        ...props.style,
    }

    function onChangeEventTargetValue(event) {
        updateField("value", event.target.value)
    }

    const elements = []

    switch (type) {
        case "input":
            elements.push(
                <input
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    style={style}
                    placeholder={props.textPlaceholder}
                    maxLength={props.textCharacterLimit?.max || undefined}
                />
            )
            break
        case "checkbox":
        case "consent":
            elements.push(
                <label
                    style={{
                        position: "relative",
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer",
                        alignItems: "center",
                        gap: gap,
                        ...style,
                    }}
                >
                    <Checkbox {...checkboxStyle} on={value === "on"} />
                    <input
                        type="checkbox"
                        name={name}
                        checked={value === "on"}
                        onChange={(event) => {
                            updateField(
                                "value",
                                event.target.checked
                                    ? "on"
                                    : type == "checkbox"
                                      ? "off"
                                      : null
                            )
                        }}
                        onFocus={revalidate}
                        style={{
                            position: "absolute",
                            pointerEvents: "none",
                            opacity: 0,
                        }}
                    />
                    {type == "checkbox"
                        ? props.checkboxText
                        : props.consentText}
                </label>
            )
            break
        case "dropdown":
            const dropdownIsDefault = !value || !options.includes(value)

            elements.push(
                <div style={{ position: "relative", ...props.style }}>
                    <select
                        value={
                            dropdownIsDefault
                                ? DROPDOWN_NONE_SELECTED_VALUE
                                : value
                        }
                        onChange={(event) => {
                            updateField(
                                "value",
                                event.target.value ==
                                    DROPDOWN_NONE_SELECTED_VALUE
                                    ? null
                                    : event.target.value
                            )
                        }}
                        onFocus={revalidate}
                        style={{
                            appearance: "none",
                            height: "100%",
                            cursor: "pointer",
                            margin: 0,
                            backgroundImage: "none",
                            ...style,
                            color: dropdownIsDefault
                                ? props.placeholderFontColor
                                : style.color,
                        }}
                    >
                        {!options.includes(props.dropdownDefaultValue) && (
                            <option
                                value={DROPDOWN_NONE_SELECTED_VALUE}
                                disabled
                            >
                                {props.dropdownNoneSelectedText}
                            </option>
                        )}
                        {options.map((option, index) =>
                            DASHES_ONLY_REGEX.test(option) &&
                            option.length >= 3 ? (
                                <hr />
                            ) : (
                                <option key={index} value={option}>
                                    {option}
                                </option>
                            )
                        )}
                    </select>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={DROPDOWN_ICON_HEIGHT}
                        height={DROPDOWN_ICON_HEIGHT}
                        viewBox="0 0 18 18"
                        fill="none"
                        stroke-width="2"
                        stroke={props.fontColor}
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        style={{
                            display: "block",
                            position: "absolute",
                            right: icon && !iconOnLeft ? 0 : pr,
                            top: `calc(50% - ${DROPDOWN_ICON_HEIGHT / 2}px)`,
                            pointerEvents: "none",
                        }}
                    >
                        <path d="M2 5.5L9 12.5L16 5.5" />
                    </svg>
                </div>
            )
            break
        case "textArea":
            elements.push(
                <textarea
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    style={{
                        display: "block",
                        height: props.resizeable
                            ? props.textAreaDefaultHeight
                            : 100,
                        minHeight: minHeight,
                        resize: props.resizeable ? "vertical" : "none",
                        ...style,
                    }}
                    placeholder={props.textPlaceholder}
                    maxLength={props.textCharacterLimit?.max || undefined}
                />
            )
            break
        case "multiSelect":
            const multiSelectOnChange = (event) => {
                const multiSelectValue = event.target.checked
                    ? [...(value || []), event.target.name]
                    : (value || []).filter((v) => v !== event.target.name)
                updateField("value", multiSelectValue)
            }

            const optionValues = (Array.isArray(value) && value) || []

            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: props.gapV,
                        ...style,
                    }}
                >
                    {props.options.map((option, index) => (
                        <label
                            key={index}
                            style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                gap: props.gapH,
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <Checkbox
                                {...checkboxStyle}
                                on={optionValues.includes(option)}
                            />
                            <input
                                type="checkbox"
                                name={option}
                                checked={optionValues.includes(option)}
                                onChange={multiSelectOnChange}
                                onFocus={revalidate}
                                style={{
                                    position: "absolute",
                                    pointerEvents: "none",
                                    opacity: 0,
                                }}
                            />
                            {option}
                        </label>
                    ))}
                </div>
            )
            break
        case "radio":
            const { radioStyle } = props
            const radioShadow = radioStyle.shadow
            const radioBorder = radioStyle.border
            const radioDotPadding = (radioStyle.size - radioStyle.dotSize) / 2

            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: props.gapV,
                        ...style,
                    }}
                >
                    {props.options.map((option, index) => (
                        <label
                            key={index}
                            onClick={() => {
                                updateField(
                                    "value",
                                    value === option ? "" : option
                                )
                            }}
                            style={{
                                position: "relative",
                                display: "flex",
                                flexDirection: "row",
                                gap: props.gapH,
                                alignItems: "center",
                                cursor: "pointer",
                            }}
                        >
                            <motion.div
                                animate={{
                                    backgroundColor:
                                        value === option
                                            ? radioStyle.fillOn
                                            : radioStyle.fillOff,
                                    boxShadow:
                                        radioShadow &&
                                        `${radioShadow.x}px ${
                                            radioShadow.y
                                        }px ${radioShadow.blur}px ${
                                            radioShadow.spread
                                        }px ${
                                            value === option
                                                ? radioShadow.colorOn
                                                : radioShadow.colorOff
                                        }`,
                                }}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: radioStyle.size,
                                    height: radioStyle.size,
                                    borderRadius: radioStyle.radius,
                                    position: "relative",
                                }}
                                initial={false}
                                transition={radioStyle.transition}
                            >
                                {radioStyle.dotColor && (
                                    <motion.div
                                        animate={{
                                            scale: value === option ? 1 : 0.5,
                                            opacity: value === option ? 1 : 0,
                                        }}
                                        style={{
                                            position: "absolute",
                                            left: radioDotPadding,
                                            top: radioDotPadding,
                                            width: radioStyle.dotSize,
                                            height: radioStyle.dotSize,
                                            backgroundColor:
                                                radioStyle.dotColor,
                                            borderRadius: Math.max(
                                                0,
                                                radioStyle.radius -
                                                    radioDotPadding
                                            ),
                                        }}
                                        initial={false}
                                        transition={radioStyle.transition}
                                    />
                                )}
                                {radioBorder && (
                                    <motion.div
                                        animate={{
                                            borderColor:
                                                value === option
                                                    ? radioBorder.colorOn
                                                    : radioBorder.colorOff,
                                        }}
                                        style={{
                                            position: "absolute",
                                            inset: 0,
                                            borderWidth:
                                                radioBorder.widthIsMixed
                                                    ? `${radioBorder.widthTop}px ${radioBorder.widthRight}px ${radioBorder.widthBottom}px ${radioBorder.widthLeft}px`
                                                    : `${radioBorder.width}px`,
                                            borderStyle: radioBorder.style,
                                            borderRadius: radioStyle.radius,
                                            pointerEvents: "none",
                                        }}
                                        initial={false}
                                        transition={radioStyle.transition}
                                    />
                                )}
                            </motion.div>
                            {option}
                        </label>
                    ))}
                </div>
            )
            break
        case "number":
            const numberUpdateFormState = (event) => {
                const roundedValue =
                    Math.round(Number(event.target.value) / props.step) *
                    props.step
                const boundedValue = Math.min(
                    Math.max(roundedValue, props.min),
                    props.max
                )
                updateField("value", boundedValue)
            }

            const numberOnFocusLost = (event) => {
                numberUpdateFormState(event)
            }

            const numberOnKeyDown = (event) => {
                if (event.key === "Enter") {
                    numberUpdateFormState(event)
                }
            }

            elements.push(
                <input
                    type="number"
                    value={value == null ? "" : value}
                    onChange={onChangeEventTargetValue} // Handle input changes
                    onFocus={revalidate}
                    onKeyDown={numberOnKeyDown}
                    onBlur={numberOnFocusLost}
                    style={style}
                    placeholder={props.numberPlaceholder}
                    min={props.min}
                    max={props.max}
                    step={props.step}
                />
            )
            break
        case "slider":
            const track = props.sliderTrack
            const handle = props.sliderHandle

            const handleCSS = `appearance: none;
                -webkit-appearance: none;
                width: ${handle.size}px;
                height: ${handle.size}px;
                border-radius: ${handle.radius}px;
                background-color: ${handle.color};
                box-sizing: border-box;
                translate: 0 ${
                    (-handle.size + track.height) / 2 -
                    (track.border
                        ? Math.min(track.border.width, track.height / 2)
                        : 0)
                }px;
                border-width: ${handle.border?.width || 0}px;
                border-style: ${handle.border ? "solid" : "none"};
                border-color: ${handle.border?.color || "none"};`

            const trackCSS = `appearance: none;
                -webkit-appearance: none;
                height: ${track.height}px;
                border-radius: ${track.radius}px;
                background-color: ${track.color};
                box-sizing: border-box;
                border-width: ${track.border?.width || 0}px;
                border-style: ${track.border ? "solid" : "none"};
                border-color: ${track.border?.color || "none"};`

            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection:
                            props.sliderLabel?.position == "right"
                                ? "row-reverse"
                                : "row",
                        gap: gap,
                        alignItems: "center",
                        ...style,
                    }}
                >
                    {props.sliderLabel && (
                        <p
                            style={{
                                margin: 0,
                                minWidth: props.sliderLabel.minWidth,
                            }}
                        >
                            {props.sliderLabel.prefix}
                            {value || props.sliderDefaultValue}
                            {props.sliderLabel.suffix}
                        </p>
                    )}
                    <input
                        type="range"
                        min={props.min}
                        max={props.max}
                        step={props.step}
                        value={value || props.sliderDefaultValue}
                        onChange={onChangeEventTargetValue}
                        onFocus={revalidate}
                        style={{
                            flex: 1,
                            appearance: "none",
                            outline: "none",
                            margin: 0,
                            cursor: "pointer",
                            height: Math.max(handle.size, track.height),
                            background: "none",
                        }}
                    />
                </div>,
                <style>
                    {`#${elementId} input[type=range]::-webkit-slider-thumb {${handleCSS}}
                    #${elementId} input[type=range]::-moz-range-thumb {${handleCSS}}

                    #${elementId} input[type=range]::-webkit-slider-runnable-track {${trackCSS}}
                    #${elementId} input[type=range]::-moz-range-track {${trackCSS}}
                    #${elementId} input[type=range]::-moz-range-progress {${trackCSS}}
                    `}
                </style>
            )
            break
        case "nps":
            const { npsButtons, npsLabels } = props
            const { innerRadius, outerRadius } = npsButtons
            const buttonBorder = npsButtons.border

            elements.push(
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: gap,
                        ...style,
                        width: undefined,
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <span>{npsLabels.left}</span>
                        <span>{npsLabels.right}</span>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            gap: npsButtons.gap,
                        }}
                    >
                        {Array.from({ length: props.npsMax + 1 }).map(
                            (_, index) => {
                                const selected = value == index
                                const radius =
                                    index == 0
                                        ? `${outerRadius}px ${innerRadius}px ${innerRadius}px ${outerRadius}px`
                                        : index == props.npsMax
                                          ? `${innerRadius}px ${outerRadius}px ${outerRadius}px ${innerRadius}px`
                                          : `${innerRadius}px`

                                return (
                                    <motion.button
                                        key={index}
                                        animate={{
                                            backgroundColor: selected
                                                ? npsButtons.selectedColor
                                                : npsButtons.deselectedColor,
                                            color: selected
                                                ? npsButtons.selectedFontColor
                                                : npsButtons.deelectedFontColor,
                                        }}
                                        style={{
                                            position: "relative",
                                            flex: 1,
                                            height: npsButtons.height,
                                            border: "none",
                                            outline: "none",
                                            borderRadius: radius,
                                            minWidth: npsButtons.minWidth,
                                            cursor: "pointer",
                                            ...npsButtons.font,
                                        }}
                                        onClick={() => {
                                            updateField("value", index)
                                            revalidate()
                                        }}
                                        initial={false}
                                        transition={npsButtons.transition}
                                    >
                                        {index}
                                        {buttonBorder && (
                                            <motion.div
                                                animate={{
                                                    borderColor: selected
                                                        ? buttonBorder.selectedColor
                                                        : buttonBorder.deselectedColor,
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    inset: 0,
                                                    borderWidth:
                                                        buttonBorder.widthIsMixed
                                                            ? `${buttonBorder.widthTop}px ${buttonBorder.widthRight}px ${buttonBorder.widthBottom}px ${buttonBorder.widthLeft}px`
                                                            : `${buttonBorder.width}px`,
                                                    borderStyle:
                                                        buttonBorder.style,
                                                    borderRadius: radius,
                                                    pointerEvents: "none",
                                                }}
                                                initial={false}
                                                transition={
                                                    npsButtons.transition
                                                }
                                            />
                                        )}
                                    </motion.button>
                                )
                            }
                        )}
                    </div>
                </div>
            )
            break
        case "date":
        case "time":
            elements.push(
                <input
                    // id={elementId}
                    type={type}
                    value={value == null ? "" : value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    style={{
                        ...style,
                    }}
                />,
                <style>{`#${elementId} input::-webkit-datetime-edit {
                    flex: 0 1 auto;
                    z-index: 1;
                    cursor: text;
                    color: ${props.fontColor};
                }
                
                #${elementId} input::-webkit-calendar-picker-indicator {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    inset: 0;
                    padding: 0;
                    opacity: 0;
                    margin: 0;
                    overflow: visible;
                    cursor: pointer;
                }`}</style>
            )
            break
        case "email":
            function emailOnFocusLost(event) {
                if (
                    event.target.value.length > 0 &&
                    !isEmailAddress(event.target.value)
                ) {
                    invalidate(INVALID_STATE.value)
                }
            }

            elements.push(
                <input
                    type="text"
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    onBlur={emailOnFocusLost}
                    style={style}
                    placeholder={props.emailPlaceholder}
                />
            )
            break
        case "phoneNumber":
            function phoneNumberOnChange(event) {
                const [phoneNumber, hasCountryCode] = formatPhoneNumber(
                    event.target.value,
                    props.phoneNumberFormat,
                    props.phoneNumberCustomFormat,
                    props.phoneNumberCountryCodeFormat,
                    props.phoneNumberDefaultCountryCode
                )

                setIsSpecialValue(!hasCountryCode)

                updateField("value", phoneNumber)
            }

            elements.push(
                <input
                    type="tel"
                    value={
                        isSpecialValue
                            ? removeCountryCode(
                                  value,
                                  props.phoneNumberFormat,
                                  props.phoneNumberCustomFormat
                              )
                            : value
                    }
                    onChange={phoneNumberOnChange}
                    onFocus={revalidate}
                    style={style}
                    placeholder={props.phoneNumberPlaceholder}
                />
            )
            break
        case "url":
            function urlOnFocusLost(event) {
                const [urlValid, domain, fullURL] = parseURL(
                    event.target.value,
                    props.urlAcceptedDomains
                )
                if (!urlValid) {
                    invalidate(INVALID_STATE.value)
                }
            }

            elements.push(
                <input
                    type="text"
                    value={value}
                    onChange={onChangeEventTargetValue}
                    onFocus={revalidate}
                    onBlur={urlOnFocusLost}
                    style={style}
                    placeholder={props.urlPlaceholder}
                />
            )
            break
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                ...props.style,
            }}
        >
            <motion.div
                ref={ref}
                id={elementId}
                animate={{
                    backgroundColor:
                        invalid && invalidStyle.backgroundColor
                            ? invalidStyle.backgroundColor
                            : props.backgroundColor,
                }}
                style={{
                    position: "relative",
                    display: "flex",
                    color: props.fontColor,
                    borderRadius: borderRadius,
                    overflow: "visible",
                    minHeight: minHeight,
                    userSelect: "none",
                    boxShadow: props.shadows,
                    ...props.font,
                    ...props.style,
                }}
                initial={false}
                transition={invalidStyle.transition}
            >
                {icon && (
                    <FormIcon
                        icon={icon}
                        style={{
                            position: "absolute",
                            top: `calc(50% - ${icon.size / 2}px)`,
                            left: iconOnLeft ? pl : undefined,
                            right: !iconOnLeft ? pr : undefined,
                        }}
                    />
                )}
                {elements}
                {border && (
                    <div
                        style={{
                            position: "absolute",
                            inset: 0,
                            borderWidth: border.widthIsMixed
                                ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                                : `${border.width}px`,
                            borderStyle: border.style,
                            borderColor: border.color,
                            borderRadius: borderRadius,
                            pointerEvents: "none",
                        }}
                    />
                )}
                {invalidStyle.border && (
                    <motion.div
                        animate={{
                            opacity: invalid ? 1 : 0,
                        }}
                        style={{
                            position: "absolute",
                            inset: 0,
                            borderWidth: invalidStyle.border.widthIsMixed
                                ? `${invalidStyle.border.widthTop}px ${invalidStyle.border.widthRight}px ${invalidStyle.border.widthBottom}px ${invalidStyle.border.widthLeft}px`
                                : `${invalidStyle.border.width}px`,
                            borderStyle: invalidStyle.border.style,
                            borderColor: invalidStyle.border.color,
                            borderRadius: borderRadius,
                            pointerEvents: "none",
                        }}
                        initial={false}
                        transition={invalidStyle.transition}
                    />
                )}
                {PLACEHOLDER_FONT_COLOR_TYPES.includes(type) && (
                    <style>{`#${elementId} input::placeholder, #${elementId} textarea::placeholder {
                        color: ${props.placeholderFontColor};
                    }`}</style>
                )}
            </motion.div>
            {invalid && invalidStyle.errorMessage && (
                <p
                    style={{
                        width: "100%",
                        margin: 0,
                        marginTop: invalidStyle.errorMessage.gap,
                        whiteSpace: "pre",
                        color: invalidStyle.errorMessage.fontColor,
                        ...invalidStyle.errorMessage.font,
                    }}
                >
                    {invalid == INVALID_STATE.required
                        ? invalidStyle.errorMessage.requiredMessage
                        : invalidStyle.errorMessage.invalidMessage}
                </p>
            )}
        </div>
    )
}

FormField.displayName = "Form Field"

addPropertyControls(FormField, {
    formId: {
        type: ControlType.Number,
        defaultValue: 0,
        step: 1,
        min: 0,
        displayStepper: true,
        title: "Form ID",
        description: "Match with Form ID on Submit Button.",
    },
    type: {
        type: ControlType.Enum,
        defaultValue: "input",
        options: [
            "input",
            "checkbox",
            "dropdown",
            "textArea",
            "email",
            "phoneNumber",
            "multiSelect",
            "radio",
            "number",
            "slider",
            "nps",
            "date",
            "time",
            "consent",
            "url",
        ],
        optionTitles: [
            "Input",
            "Checkbox",
            "Dropdown",
            "Text Area",
            "Email",
            "Phone Number",
            "Multi-Select",
            "Radio",
            "Number",
            "Slider",
            "Net Promoter Score",
            "Date",
            "Time",
            "Consent",
            "URL",
        ],
    },
    name: {
        type: ControlType.String,
        defaultValue: "fieldName",
    },
    required: {
        type: ControlType.Boolean,
        defaultValue: false,
        hidden: (props) => NO_REQUIRED_TYPES.includes(props.type),
    },
    prefill: {
        type: ControlType.Boolean,
        defaultValue: false,
        title: "Pre-fill",
        hidden: (props) => NO_PREFILL_TYPES.includes(props.type),
    },
    prefillUrlParameter: {
        type: ControlType.String,
        defaultValue: "",
        placeholder: "Pre-fill URL Parameter",
        title: " ",
        hidden: (props) =>
            NO_PREFILL_TYPES.includes(props.type) || !props.prefill,
    },
    textPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "Type...",
        hidden: (props) => !TEXT_PLACEHOLDER_TYPES.includes(props.type),
    },
    numberPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "0",
        hidden: (props) => props.type != "number",
    },
    emailPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "hello@example.com",
        hidden: (props) => props.type != "email",
    },
    textCharacterLimit: {
        type: ControlType.Object,
        optional: true,
        title: "Character Limit",
        hidden: (props) => !CHARACTER_LIMIT_TYPES.includes(props.type),
        controls: {
            min: {
                type: ControlType.Number,
                defaultValue: 0,
                step: 1,
                title: "Min Characters",
            },
            max: {
                type: ControlType.Number,
                defaultValue: 100,
                step: 1,
                title: "Max Characters",
            },
        },
    },
    phoneNumberFormat: {
        title: "Format",
        type: ControlType.Enum,
        defaultValue: "(123) 456 - 7890",
        options: PHONE_NUMBER_FORMATS,
        // options: [...PHONE_NUMBER_FORMATS, "custom"],
        // optionTitles: [...PHONE_NUMBER_FORMATS, "Custom Format"],
        hidden: (props) => props.type != "phoneNumber",
    },
    // phoneNumberCustomFormat: {
    //     title: " ",
    //     type: ControlType.String,
    //     defaultValue: "(XXX) XXX - XXXX",
    //     hidden: (props) =>
    //         props.type != "phoneNumber" || props.phoneNumberFormat != "custom",
    // },
    phoneNumberDefaultCountryCode: {
        title: "Default Country Code",
        type: ControlType.String,
        defaultValue: "1",
        hidden: (props) => props.type != "phoneNumber",
    },
    phoneNumberCountryCodeFormat: {
        title: "Country Code Format",
        type: ControlType.Enum,
        defaultValue: "+1",
        options: ["+1", "(1)", "(+1)", "1", "noSpace"],
        optionTitles: ["+1", "(1)", "(+1)", "1", "1 (No Space)"],
        hidden: (props) => props.type != "phoneNumber",
    },
    phoneNumberPlaceholder: {
        title: "Placeholder",
        type: ControlType.String,
        defaultValue: "+1 (123) 456 - 7890",
        hidden: (props) => props.type != "phoneNumber",
    },
    urlAcceptedDomains: {
        type: ControlType.Object,
        optional: true,
        title: "Accepted Domains",
        controls: {
            mode: {
                type: ControlType.Enum,
                defaultValue: "blacklist",
                options: ["blacklist", "whitelist"],
                optionTitles: ["Blacklist", "Whitelist"],
                displaySegmentedControl: true,
            },
            domains: {
                type: ControlType.Array,
                control: {
                    type: ControlType.String,
                    placeholder: "example.com",
                },
                description:
                    "*Blacklist:* URLs from any domains in the list are rejected.\n*Whitelist:* Only URLs from domains in the list are accepted.",
            },
        },
        hidden: (props) => props.type != "url",
    },
    urlPlaceholder: {
        type: ControlType.String,
        defaultValue: "framerforms.com",
        placeholder: "example.com",
        title: "Placeholder",
        hidden: (props) => props.type != "url",
    },
    consentText: {
        type: ControlType.String,
        defaultValue: "I agree to the Terms & Conditions.",
        title: "Text",
        hidden: (props) => props.type != "consent",
    },
    checkboxText: {
        type: ControlType.String,
        defaultValue: "Checkbox",
        title: "Text",
        hidden: (props) => props.type != "checkbox",
    },
    options: {
        title: "Options",
        type: ControlType.Array,
        propertyControl: {
            type: ControlType.String,
        },
        defaultValue: ["Option 1", "Option 2", "Option 3"],
        hidden: (props) => !OPTIONS_TYPES.includes(props.type),
    },
    checkboxStyle: {
        type: ControlType.Object,
        title: "Checkbox",
        buttonTitle: "Style",
        controls: {
            fillOn: {
                type: ControlType.Color,
                defaultValue: "#0075FF",
            },
            fillOff: {
                type: ControlType.Color,
                defaultValue: "#EDEDED",
            },
            size: {
                type: ControlType.Number,
                defaultValue: 16,
                min: 1,
                step: 1,
            },
            radius: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 0,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                defaultValue: {
                    colorOn: "rgba(219, 219, 219, 0)",
                    colorOff: "#DBDBDB",
                    width: 1,
                    style: "solid",
                },
                controls: {
                    colorOn: {
                        type: ControlType.Color,
                        defaultValue: "rgba(219, 219, 219, 0)",
                    },
                    colorOff: {
                        type: ControlType.Color,
                        defaultValue: "#DBDBDB",
                    },
                    width: {
                        type: ControlType.FusedNumber,
                        defaultValue: 1,
                        toggleKey: "widthIsMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "widthTop",
                            "widthRight",
                            "widthBottom",
                            "widthLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    style: {
                        type: ControlType.Enum,
                        defaultValue: "solid",
                        options: ["solid", "dashed", "dotted", "double"],
                        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
                    },
                },
            },
            shadow: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    colorOn: {
                        type: ControlType.Color,
                        defaultValue: "rgba(0, 0, 0, 0.25)",
                    },
                    colorOff: {
                        type: ControlType.Color,
                        defaultValue: "rgba(0, 0, 0, 0.25)",
                    },
                    x: {
                        type: ControlType.Number,
                        defaultValue: 0,
                        displayStepper: true,
                    },
                    y: {
                        type: ControlType.Number,
                        defaultValue: 2,
                        displayStepper: true,
                    },
                    blur: {
                        type: ControlType.Number,
                        defaultValue: 4,
                        min: 0,
                        displayStepper: true,
                    },
                    spread: {
                        type: ControlType.Number,
                        defaultValue: 0,
                        displayStepper: true,
                    },
                },
            },
            icon: {
                type: ControlType.Object,
                optional: true,
                defaultValue: {
                    size: 12,
                    color: "#FFF",
                    rounded: true,
                },
                buttonTitle: "Style",
                title: "Check",
                controls: {
                    size: {
                        type: ControlType.Number,
                        defaultValue: 16,
                        min: 1,
                        step: 1,
                    },
                    lineWidth: {
                        type: ControlType.Number,
                        defaultValue: 1.5,
                        min: 0.1,
                        step: 0.1,
                    },
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#FFF",
                    },
                    rounded: {
                        type: ControlType.Boolean,
                        defaultValue: true,
                    },
                },
            },
            transition: {
                type: ControlType.Transition,
                defaultValue: {
                    type: "spring",
                    stiffness: 1500,
                    damping: 70,
                },
            },
        },
        hidden: (props) => !CHECKBOX_TYPES.includes(props.type),
    },
    radioStyle: {
        type: ControlType.Object,
        title: "Radio",
        buttonTitle: "Style",
        controls: {
            fillOn: {
                type: ControlType.Color,
                defaultValue: "#EDEDED",
            },
            fillOff: {
                type: ControlType.Color,
                defaultValue: "#EDEDED",
            },
            size: {
                type: ControlType.Number,
                defaultValue: 16,
                min: 1,
                step: 1,
            },
            radius: {
                type: ControlType.Number,
                defaultValue: 8,
                min: 0,
            },
            dotColor: {
                type: ControlType.Color,
                defaultValue: "#0075FF",
                optional: true,
            },
            dotSize: {
                type: ControlType.Number,
                defaultValue: 8,
                min: 1,
                step: 1,
                hidden: (props) => !props.dotColor,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                defaultValue: {
                    colorOn: "#0075FF",
                    colorOff: "#DBDBDB",
                    width: 1,
                    style: "solid",
                },
                controls: {
                    colorOn: {
                        type: ControlType.Color,
                        defaultValue: "#0075FF",
                    },
                    colorOff: {
                        type: ControlType.Color,
                        defaultValue: "#DBDBDB",
                    },
                    width: {
                        type: ControlType.FusedNumber,
                        defaultValue: 1,
                        toggleKey: "widthIsMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "widthTop",
                            "widthRight",
                            "widthBottom",
                            "widthLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    style: {
                        type: ControlType.Enum,
                        defaultValue: "solid",
                        options: ["solid", "dashed", "dotted", "double"],
                        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
                    },
                },
            },
            shadow: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    colorOn: {
                        type: ControlType.Color,
                        defaultValue: "rgba(0, 0, 0, 0.25)",
                    },
                    colorOff: {
                        type: ControlType.Color,
                        defaultValue: "rgba(0, 0, 0, 0.25)",
                    },
                    x: {
                        type: ControlType.Number,
                        defaultValue: 0,
                        displayStepper: true,
                    },
                    y: {
                        type: ControlType.Number,
                        defaultValue: 2,
                        displayStepper: true,
                    },
                    blur: {
                        type: ControlType.Number,
                        defaultValue: 4,
                        min: 0,
                        displayStepper: true,
                    },
                    spread: {
                        type: ControlType.Number,
                        defaultValue: 0,
                        displayStepper: true,
                    },
                },
            },
            transition: {
                type: ControlType.Transition,
                defaultValue: {
                    type: "spring",
                    stiffness: 1500,
                    damping: 70,
                },
            },
        },
        hidden: (props) => props.type !== "radio",
    },
    npsMax: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 1,
        step: 1,
        displayStepper: true,
        title: "Max",
        hidden: (props) => props.type != "nps",
    },
    npsLabels: {
        type: ControlType.Object,
        title: "Labels",
        controls: {
            left: {
                type: ControlType.String,
                defaultValue: "Not Likely",
            },
            right: {
                type: ControlType.String,
                defaultValue: "Extremely Likely",
            },
        },
        hidden: (props) => props.type != "nps",
    },
    npsButtons: {
        type: ControlType.Object,
        buttonTitle: "Options",
        title: "Buttons",
        controls: {
            selectedColor: {
                type: ControlType.Color,
                defaultValue: "#0075FF",
                title: "Selected",
            },
            selectedFontColor: {
                type: ControlType.Color,
                defaultValue: "#FFFFFF",
                title: "Selected Font Color",
            },
            deselectedColor: {
                type: ControlType.Color,
                defaultValue: "#F0F0F0",
                title: "Deselected",
            },
            deselectedFontColor: {
                type: ControlType.Color,
                defaultValue: "#000000",
                title: "Deslected Font Color",
            },
            font: {
                type: "font",
                controls: "extended",
                defaultFontType: "sans-serif",
                defaultValue: {
                    fontSize: 12,
                    lineHeight: 1,
                },
            },
            innerRadius: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 0,
                step: 1,
            },
            outerRadius: {
                type: ControlType.Number,
                defaultValue: 8,
                min: 0,
                step: 1,
            },
            gap: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 0,
                step: 1,
            },
            height: {
                type: ControlType.Number,
                defaultValue: 40,
                min: 0,
                step: 1,
            },
            minWidth: {
                type: ControlType.Number,
                defaultValue: 30,
                min: 0,
                step: 1,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    selectedColor: {
                        type: ControlType.Color,
                        defaultValue: "#004CA8",
                        title: "Selected",
                    },
                    deselectedColor: {
                        type: ControlType.Color,
                        defaultValue: "#222222",
                        title: "Deselected",
                    },
                    width: {
                        type: ControlType.FusedNumber,
                        defaultValue: 1,
                        toggleKey: "widthIsMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "widthTop",
                            "widthRight",
                            "widthBottom",
                            "widthLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    style: {
                        type: ControlType.Enum,
                        defaultValue: "solid",
                        options: ["solid", "dashed", "dotted", "double"],
                        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
                    },
                },
            },
            transition: {
                type: ControlType.Transition,
                defaultValue: {
                    type: "spring",
                    stiffness: 1200,
                    damping: 70,
                },
            },
        },
        hidden: (props) => props.type != "nps",
    },
    sliderHandle: {
        type: ControlType.Object,
        buttonTitle: "Options",
        title: "Handle",
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#0075FF",
            },
            size: {
                type: ControlType.Number,
                defaultValue: 16,
                min: 1,
                step: 1,
            },
            radius: {
                type: ControlType.Number,
                defaultValue: 8,
                min: 0,
                step: 1,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#0069E0",
                    },
                    width: {
                        type: ControlType.Number,
                        defaultValue: 1,
                        min: 0,
                        step: 1,
                    },
                },
            },
        },
        hidden: (props) => props.type != "slider",
    },
    sliderTrack: {
        type: ControlType.Object,
        buttonTitle: "Options",
        title: "Track",
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#D9D9D9",
            },
            height: {
                type: ControlType.Number,
                defaultValue: 4,
                min: 1,
                step: 1,
            },
            radius: {
                type: ControlType.Number,
                defaultValue: 2,
                min: 0,
                step: 1,
            },
            border: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#000",
                    },
                    width: {
                        type: ControlType.Number,
                        defaultValue: 1,
                        min: 0,
                        step: 1,
                    },
                },
            },
        },
        hidden: (props) => props.type != "slider",
    },
    min: {
        title: "Min",
        type: ControlType.Number,
        defaultValue: 0,
        hidden: (props) => !MIN_MAX_STEP_TYPES.includes(props.type),
    },
    max: {
        title: "Max",
        type: ControlType.Number,
        defaultValue: 100,
        hidden: (props) => !MIN_MAX_STEP_TYPES.includes(props.type),
    },
    step: {
        type: ControlType.Number,
        defaultValue: 1,
        hidden: (props) => !MIN_MAX_STEP_TYPES.includes(props.type),
    },
    resizeable: {
        type: ControlType.Boolean,
        defaultValue: true,
        hidden: (props) => props.type != "textArea",
    },
    textAreaDefaultHeight: {
        type: ControlType.Number,
        defaultValue: 100,
        min: 0,
        step: 1,
        title: "Height",
        hidden: (props) => props.type != "textArea" || !props.resizeable,
    },
    sliderDefaultValue: {
        type: ControlType.Number,
        defaultValue: 50,
        title: "Default Value",
        hidden: (props) => props.type != "slider",
    },
    sliderLabel: {
        type: ControlType.Object,
        defaultValue: {
            defaultValue: "left",
            minWidth: 24,
        },
        optional: true,
        controls: {
            position: {
                type: ControlType.Enum,
                defaultValue: "left",
                options: ["left", "right"],
                optionTitles: ["Left", "Right"],
                displaySegmentedControl: true,
            },
            minWidth: {
                type: ControlType.Number,
                defaultValue: 24,
                min: 0,
                step: 1,
            },
            prefix: {
                type: ControlType.String,
            },
            suffix: {
                type: ControlType.String,
            },
        },
        hidden: (props) => props.type != "slider",
    },
    dropdownDefaultValue: {
        type: ControlType.String,
        title: "Default Value",
        placeholder: "Default Value",
        hidden: (props) => props.type !== "dropdown",
    },
    dropdownNoneSelectedText: {
        type: ControlType.String,
        defaultValue: "Select an option",
        title: "None Selected Text",
        hidden: (props) =>
            props.options.includes(props.dropdownDefaultValue) ||
            props.type != "dropdown",
    },
    backgroundColor: {
        type: ControlType.Color,
        defaultValue: "#FFF",
        optional: true,
        title: "Fill",
    },
    fontColor: {
        type: ControlType.Color,
        defaultValue: "#000",
        hidden: (props) => props.type == "slider" && !props.sliderLabel,
    },
    placeholderFontColor: {
        type: ControlType.Color,
        defaultValue: "rgba(0,0,0,0.5)",
        hidden: (props) => !PLACEHOLDER_FONT_COLOR_TYPES.includes(props.type),
    },
    font: {
        type: "font",
        controls: "extended",
        defaultFontType: "sans-serif",
        defaultValue: {
            fontSize: 14,
            lineHeight: 1.5,
        },
        hidden: (props) => props.type == "slider" && !props.sliderLabel,
    },
    gap: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        hidden: (props) => !GAP_TYPES.includes(props.type),
    },
    gapH: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        hidden: (props) => !GAP_HV_TYPES.includes(props.type),
    },
    gapV: {
        type: ControlType.Number,
        defaultValue: 10,
        min: 0,
        step: 1,
        hidden: (props) => !GAP_HV_TYPES.includes(props.type),
    },
    padding: {
        type: ControlType.FusedNumber,
        defaultValue: 16,
        toggleKey: "paddingIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "paddingTop",
            "paddingRight",
            "paddingBottom",
            "paddingLeft",
        ],
        valueLabels: ["T", "R", "B", "L"],
        min: 0,
    },
    radius: {
        type: ControlType.FusedNumber,
        defaultValue: 8,
        toggleKey: "radiusIsMixed",
        toggleTitles: ["All", "Individual"],
        valueKeys: [
            "radiusTopLeft",
            "radiusTopRight",
            "radiusBottomRight",
            "radiusBottomLeft",
        ],
        valueLabels: ["TL", "TR", "BR", "BL"],
        min: 0,
    },
    border: {
        type: ControlType.Object,
        optional: true,
        controls: {
            color: {
                type: ControlType.Color,
                defaultValue: "#222",
            },
            width: {
                type: ControlType.FusedNumber,
                defaultValue: 1,
                toggleKey: "widthIsMixed",
                toggleTitles: ["All", "Individual"],
                valueKeys: [
                    "widthTop",
                    "widthRight",
                    "widthBottom",
                    "widthLeft",
                ],
                valueLabels: ["T", "R", "B", "L"],
                min: 0,
            },
            style: {
                type: ControlType.Enum,
                defaultValue: "solid",
                options: ["solid", "dashed", "dotted", "double"],
                optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
            },
        },
    },
    shadows: {
        type: ControlType.BoxShadow,
    },
    icon: iconPropertyControls,
    invalidStyle: {
        type: ControlType.Object,
        buttonTitle: "Options",
        controls: {
            backgroundColor: {
                type: ControlType.Color,
                defaultValue: "#FFF5F5",
                optional: true,
                title: "Fill",
            },
            border: {
                type: ControlType.Object,
                optional: true,
                defaultValue: {
                    color: "#FF0000",
                    width: 2,
                    style: "solid",
                },
                controls: {
                    color: {
                        type: ControlType.Color,
                        defaultValue: "#FF0000",
                    },
                    width: {
                        type: ControlType.FusedNumber,
                        defaultValue: 2,
                        toggleKey: "widthIsMixed",
                        toggleTitles: ["All", "Individual"],
                        valueKeys: [
                            "widthTop",
                            "widthRight",
                            "widthBottom",
                            "widthLeft",
                        ],
                        valueLabels: ["T", "R", "B", "L"],
                        min: 0,
                    },
                    style: {
                        type: ControlType.Enum,
                        defaultValue: "solid",
                        options: ["solid", "dashed", "dotted", "double"],
                        optionTitles: ["Solid", "Dashed", "Dotted", "Double"],
                    },
                },
            },
            errorMessage: {
                type: ControlType.Object,
                optional: true,
                controls: {
                    invalidMessage: {
                        type: ControlType.String,
                        defaultValue: "Invalid value.",
                        displayTextArea: true,
                    },
                    requiredMessage: {
                        type: ControlType.String,
                        defaultValue: "This field is required.",
                        displayTextArea: true,
                    },
                    fontColor: {
                        type: ControlType.Color,
                        defaultValue: "#FF0000",
                    },
                    font: {
                        type: "font",
                        controls: "extended",
                        defaultFontType: "sans-serif",
                        defaultValue: {
                            fontSize: 14,
                            lineHeight: 1,
                        },
                    },
                    gap: {
                        type: ControlType.Number,
                        defaultValue: 8,
                        min: 0,
                        step: 1,
                    },
                },
            },
            transition: {
                type: ControlType.Transition,
                defaultValue: {
                    type: "spring",
                    stiffness: 1200,
                    damping: 70,
                },
            },
        },
    },
})

// Utility functions

function isEmailAddress(string) {
    return /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,64}$/.test(string)
}

function formatPhoneNumber(
    phoneNumberString,
    format,
    customFormat,
    countryCodeFormat,
    defaultCountryCode
) {
    // Remove all non-numeric characters
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "")

    // Check the length to decide if there's a country code
    const match = cleaned.match(/^(\d{1,4})?(\d{3})(\d{3})(\d{4})$/)

    let hasCountryCode = false
    let phoneNumber = cleaned

    if (match) {
        hasCountryCode = Boolean(match[1])

        let intlCode = ""
        const countryCode = match[1] || defaultCountryCode
        if (countryCode?.length) {
            switch (countryCodeFormat) {
                case "+1":
                    intlCode = `+${countryCode} `
                    break
                case "(1)":
                    intlCode = `(${countryCode}) `
                    break
                case "(+1)":
                    intlCode = `(+${countryCode}) `
                    break
                case "1":
                    intlCode = `${countryCode} `
                    break
                case "noSpace":
                    intlCode = `${countryCode}`
                    break
            }
        }

        switch (format) {
            case "custom":
                let formattedNumber = ""
                let digitIndex = 0

                for (let i = 0; i < customFormat.length; i++) {
                    if (customFormat[i] === "X") {
                        formattedNumber += cleaned[digitIndex] || ""
                        digitIndex++
                    } else {
                        formattedNumber += customFormat[i]
                    }
                }

                phoneNumber = intlCode + formattedNumber
                // phoneNumber = // ChatGPT please fill this part in
                break
            case "(123) 456 - 7890":
                phoneNumber = `${intlCode}(${match[2]}) ${match[3]} - ${match[4]}`
                break
            case "(123) 456-7890":
                phoneNumber = `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`
                break
            case "123-456-7890":
                phoneNumber = `${intlCode}${match[2]}-${match[3]}-${match[4]}`
                break
            case "123.456.7890":
                phoneNumber = `${intlCode}${match[2]}.${match[3]}.${match[4]}`
                break
            case "123 456 7890":
                phoneNumber = `${intlCode}${match[2]} ${match[3]} ${match[4]}`
                break
            case "1234567890":
                phoneNumber = `${intlCode}${match[2]}${match[3]}${match[4]}`
                break
            case "12-3456-7890":
                phoneNumber = `${intlCode}${match[2].slice(
                    0,
                    -1
                )}-${match[2].charAt(2)}${match[3]}-${match[4]}`
                break
        }
    }

    return [phoneNumber, hasCountryCode]
}

function removeCountryCode(
    phoneNumber: string,
    phoneNumberFormat,
    phoneNumberCustomFormat
) {
    let firstSpaceIndex = phoneNumber?.indexOf(" ") || -1
    if (firstSpaceIndex === -1) {
        return phoneNumber // No space found
    }

    let numberLength = 10
    if (phoneNumberFormat == "custom") {
        numberLength = (phoneNumberCustomFormat.match(/X/g) || []).length
    }

    let count = 0
    let hasCountryCode = false
    for (let i = firstSpaceIndex + 1; i < phoneNumber.length; i++) {
        if (/\d/.test(phoneNumber[i])) {
            // Check if the character is a number
            count++
            if (count >= numberLength) {
                hasCountryCode = true
                break
            }
        }
    }

    if (hasCountryCode) {
        return phoneNumber.substring(firstSpaceIndex + 1)
    }

    return phoneNumber
}

function Checkbox(props) {
    const { on, border, shadow } = props

    return (
        <motion.div
            animate={{
                backgroundColor: on ? props.fillOn : props.fillOff,
                boxShadow:
                    shadow &&
                    `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${
                        shadow.spread
                    }px ${on ? shadow.colorOn : shadow.colorOff}`,
            }}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: props.size,
                height: props.size,
                borderRadius: props.radius,
                position: "relative",
            }}
            initial={false}
            transition={props.transition}
        >
            {props.icon && (
                <motion.svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={props.icon.size}
                    height={props.icon.size}
                    viewBox="0 0 24 24"
                    strokeWidth={props.icon.lineWidth * (24 / props.icon.size)}
                    stroke={props.icon.color}
                    fill="none"
                    strokeLinecap={props.icon.rounded ? "round" : "butt"}
                    strokeLinejoin={props.icon.rounded ? "round" : "miter"}
                    animate={{
                        opacity: on ? 1 : 0,
                    }}
                    style={{
                        display: "block",
                    }}
                    initial={false}
                    transition={props.transition}
                >
                    <path d="M5 12l5 5l10 -10" />
                </motion.svg>
            )}
            {border && (
                <motion.div
                    animate={{
                        borderColor: on ? border.colorOn : border.colorOff,
                    }}
                    style={{
                        position: "absolute",
                        inset: 0,
                        borderWidth: border.widthIsMixed
                            ? `${border.widthTop}px ${border.widthRight}px ${border.widthBottom}px ${border.widthLeft}px`
                            : `${border.width}px`,
                        borderStyle: border.style,
                        borderRadius: props.radius,
                        pointerEvents: "none",
                    }}
                    initial={false}
                    transition={props.transition}
                />
            )}
        </motion.div>
    )
}

// Returns [isValid, domain, fullURL]
const INVALID_URL_RETURN = [false, null, null]
function parseURL(value, urlAcceptedDomains) {
    // Empty strings can still be "valid" if the field is not required
    if (value.length == 0) {
        return [true, "", ""]
    }

    // Every URL has a period so check for it in the string.
    if (!value.includes(".")) {
        return INVALID_URL_RETURN
    }

    try {
        let fullURL = value

        // Add https:// if not already included
        if (!fullURL.match(/^https?:\/\//)) {
            fullURL = "https://" + fullURL
        }

        const url = new URL(fullURL)

        if (!/^[^\.]+\.[a-z]+$/i.test(url.hostname)) {
            return INVALID_URL_RETURN
        }

        if (urlAcceptedDomains) {
            if (urlAcceptedDomains.mode == "blacklist") {
                if (urlAcceptedDomains.domains.includes(url.hostname)) {
                    return INVALID_URL_RETURN
                }
            } else {
                if (!urlAcceptedDomains.domains.includes(url.hostname)) {
                    return INVALID_URL_RETURN
                }
            }
        }

        return [true, url.hostname, fullURL]
    } catch (error) {
        return INVALID_URL_RETURN
    }
}

function isNumber(str) {
    return !isNaN(str) && isFinite(str)
}
